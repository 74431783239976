import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <section class="bg-secondary flex flex-col pt-12 md:pt-24 md:px-60">
        <h1 className="text-3xl md:text-4xl">اكتشف <span className="text-primary font-bold">"طربيزه"</span> ... الطاولة الرقمية الخاص بك</h1>

        <p className="text-xl md:text-2xl mt-4">
          تقدم <span class="text-primary font-bold">طربيزه</span> منصة مبتكرة تتيح لك عرض منتجاتك وبيعها دون الحاجة إلى الانتظار حتى البازار القادم. بفضل <span class="text-primary font-bold">طربيزه</span>، يمكنك إنشاء طاولة افتراضية ودعوة العملاء للتسوق من أي مكان وفي أي وقت. سواء كنت بائعًا أو مشترًا، ستجد في <span class="text-primary font-bold">طربيزه</span> كل ما تحتاجه لتجربة تسوق مثالية
        </p>

        <section className="flex justify-center items-center gap-2 mt-12">
          <Link to="/waiting-list" className="bg-primary text-secondary rounded-md py-1 px-4 font-bold text-xl">إنضم لقائمة الانتظار الأن</Link>
          <a target="_blank" href="https://m.me/61564007667689" className="bg-transparent border-2 border-primary text-primary rounded-md py-1 px-4 font-bold text-xl">تواصل معنا</a>
        </section>
      </section>
    </>
  );
};

export default Header;
