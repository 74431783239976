import { Link } from "react-router-dom";
import logo from "../logo.png";

const Navbar = () => {
  return (
    <>
      <nav class="relative bg-secondary border-none pt-8 md:pt-0">
        <div class="px-8 md:px-16 mx-auto">
          <div class="flex items-center justify-between">
            <Link to="/waiting-list" className="bg-primary text-secondary rounded-md py-1 px-4 font-bold text-xl">إنضم لقائمة الانتظار الأن</Link>

            <a href="#">
              <img class="w-auto h-16 md:h-24" src={logo} alt="logo" />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
