import React, { useEffect, useState } from 'react';

const WaitingListPage = () => {
    const [storeName, setStoreName] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneCode, setPhoneCode] = useState('+971');
    const [about, setAbout] = useState('');

    // State for error messages
    const [errors, setErrors] = useState({
        storeName: '',
        email: '',
        country: '',
        phone: '',
        about: '',
        backend: ''
    });

    // State for button disabled status
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // State for loading status
    const [isLoading, setIsLoading] = useState(false);

    // State for success message
    const [successMessage, setSuccessMessage] = useState('');

    // Validate storeName
    const validateStoreName = (value) => {
        if (value.trim() === '') {
            return 'اسم المتجر مطلوب';
        }
        return '';
    };

    // Validate email
    const validateEmail = (value) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value.trim())) {
            return 'البريد الإلكتروني غير صحيح';
        }
        return '';
    };

    // Validate country
    const validateCountry = (value) => {
        if (value === '') {
            return 'البلد مطلوب';
        }
        return '';
    };

    // Validate phone
    const validatePhone = (value) => {
        if (value.trim() === '') {
            return 'رقم الهاتف مطلوب';
        }
        return '';
    };

    // Validate about
    const validateAbout = (value) => {
        if (value.trim() === '') {
            return 'عن المتجر مطلوب';
        }
        return '';
    };

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let error = '';

        switch (name) {
            case 'storeName':
                setStoreName(value);
                error = validateStoreName(value);
                setErrors((prev) => ({ ...prev, storeName: error }));
                break;
            case 'email':
                setEmail(value);
                error = validateEmail(value);
                setErrors((prev) => ({ ...prev, email: error }));
                break;
            case 'country':
                setCountry(value);
                error = validateCountry(value);
                setErrors((prev) => ({ ...prev, country: error }));
                break;
            case 'phone':
                setPhone(value);
                error = validatePhone(value);
                setErrors((prev) => ({ ...prev, phone: error }));
                break;
            case 'about':
                setAbout(value);
                error = validateAbout(value);
                setErrors((prev) => ({ ...prev, about: error }));
                break;
            default:
                break;
        }
    };

    const handlePhoneCodeChange = (e) => {
        setPhoneCode(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Perform final validation before submission
        const finalErrors = {
            storeName: validateStoreName(storeName),
            email: validateEmail(email),
            country: validateCountry(country),
            phone: validatePhone(phone),
            about: validateAbout(about)
        };

        setErrors(finalErrors);

        // Check if there are any errors
        if (Object.values(finalErrors).some((error) => error)) {
            setIsLoading(false);
            return; // Stop submission if there are validation errors
        }

        // Prepare data for submission
        const formData = {
            name: storeName,
            email: email,
            country: country,
            phone: `${phoneCode}${phone}`,
            description: about
        };

        try {
            const response = await fetch('https://development.tarabezaa.com/api/v1/waiting_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            console.log(result);

            if (response.ok) {
                if (result.success) {
                    // Handle successful response
                    setSuccessMessage('تم التسجيل بنجاح!');
                    // Clear the inputs
                    setStoreName('');
                    setEmail('');
                    setCountry('');
                    setPhone('');
                    setAbout('');
                    setErrors({
                        storeName: '',
                        email: '',
                        country: '',
                        phone: '',
                        about: ''
                    });
                }
            } else {
                setErrors({
                    backend: ''
                });
                setErrors((prev) => ({ ...prev, backend: result.error.message }));
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Check if any field is empty to disable the button
        const isFormValid = storeName && email && country && phone && about;
        setIsButtonDisabled(!isFormValid);
    }, [storeName, email, country, phone, about]);

    return (
        <div className="App font-zain" dir='rtl'>
            <section className="bg-white">
                <div className="flex justify-center min-h-screen">
                    <div className="hidden bg-cover lg:block lg:w-2/5" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1515021477923-4d707a1cd1ba?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')" }}>
                    </div>

                    <div className="flex items-center w-full max-w-3xl p-8 mx-auto lg:px-12 lg:w-3/5 text-start">
                        <div className="w-full">
                            <h1 className="text-2xl font-bold text-gray-700 capitalize">
                                قم بالانضمام الى قائمة الأنتظار الان !
                            </h1>

                            <p className="mt-4 text-gray-500">
                                سجل الان وانضم لقائمة الانتظار مع اكثر من الف متجر للاستفادة من الخدمات التي تقدمها منصة <span className='text-primary font-bold'>طربيزه</span>
                            </p>

                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-2">
                                    <div>
                                        <label className="block mb-2 text-sm text-gray-600">إسم المتجر</label>
                                        <input
                                            type="text"
                                            name="storeName"
                                            placeholder="طربيزه"
                                            value={storeName}
                                            onChange={handleInputChange}
                                            className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:ring-secondary focus:outline-none focus:ring focus:ring-opacity-20"
                                        />
                                        {errors.storeName && <div className="text-red-500">{errors.storeName}</div>}
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-sm text-gray-600">البلد</label>
                                        <select
                                            name="country"
                                            value={country}
                                            onChange={handleInputChange}
                                            className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:ring-secondary focus:outline-none focus:ring focus:ring-opacity-20"
                                        >
                                            <option value="">اختر البلد</option>
                                            <option value="الإمارات العربية المتحدة">الإمارات العربية المتحدة</option>
                                            <option value="المملكة العربية السعودية">المملكة العربية السعودية</option>
                                            <option value="جمهورية مصر العربية">جمهورية مصر العربية</option>
                                        </select>
                                        {errors.country && <div className="text-red-500">{errors.country}</div>}
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-sm text-gray-600">البريد الإلكتروني</label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="mystore@example.com"
                                            value={email}
                                            onChange={handleInputChange}
                                            className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:ring-secondary focus:outline-none focus:ring focus:ring-opacity-20"
                                        />
                                        {errors.email && <div className="text-red-500">{errors.email}</div>}
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm text-gray-600">رقم الهاتف</label>
                                        <div className="flex">
                                            <input
                                                type="text"
                                                name="phone"
                                                placeholder="xxx-xxx-xxx"
                                                value={phone}
                                                onChange={handleInputChange}
                                                className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-tr-md rounded-br-md focus:ring-secondary focus:outline-none focus:ring focus:ring-opacity-20"
                                            />
                                            <select
                                                name="phoneCode"
                                                value={phoneCode}
                                                onChange={handlePhoneCodeChange}
                                                className="block px-5 py-3 mt-2 text-gray-700 bg-white border border-gray-200 rounded-tl-md rounded-bl-md focus:ring-secondary focus:outline-none focus:ring focus:ring-opacity-20"
                                            >
                                                <option value="+971">+971</option>
                                                <option value="+966">+966</option>
                                                <option value="+20">+20</option>
                                            </select>
                                        </div>
                                        {errors.phone && <div className="text-red-500">{errors.phone}</div>}
                                    </div>

                                   
                                </div>

                                <div>
                                        <label className="block mb-2 text-sm text-gray-600">عن المتجر</label>
                                        <textarea
                                            rows="8"
                                            name="about"
                                            placeholder="متجر مختص ب ..."
                                            value={about}
                                            onChange={handleInputChange}
                                            className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-secondary focus:ring-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                                        ></textarea>
                                        {errors.about && <div className="text-red-500">{errors.about}</div>}
                                    </div>

                                {successMessage && (
                                    <div className="mt-4 p-4 text-green-700 bg-green-100 rounded-lg">
                                        {successMessage}
                                    </div>
                                )}

                                {errors.backend && (
                                    <div className="mt-4 p-4 text-red-700 bg-red-100 rounded-lg">
                                        {errors.backend}
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    className={`mt-6 flex items-center justify-between w-full px-6 py-3 text-sm tracking-wide text-secondary capitalize transition-colors duration-300 transform bg-primary rounded-lg focus:outline-none focus:ring focus:ring-opacity-50 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={isButtonDisabled || isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            <span>جار الإرسال ...</span>
                                            <svg className="animate-spin w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                            </svg>
                                        </>
                                    ) : (
                                        <>
                                            <span>سجل الان</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 rtl:-scale-x-100" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 0 01-1.414 0z"
                                                    clipRule="evenodd" />
                                            </svg>
                                        </>
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default WaitingListPage;
