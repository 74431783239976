import Header from '../components/Header';
import Navbar from '../components/Navbar';

const HomePage = () => {
    return (
        <div className="App font-zain bg-secondary h-screen">
        <Navbar />
        <Header />
      </div>
    );
  };
  
  export default HomePage;
  
